<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="detail">
            <img :src="data.picUrl[0]" alt="" />
            <div class="right">
                <div>{{ data.createTitle }}</div>
                <span>{{ options[data.createType] }}</span>
                <div>{{ data.craeteDec }}</div>
            </div>
        </div>
        <div class="title">图片</div>
        <div class="content">
            <div v-for="(item, index) in data.picUrl" :key="index"><img :src="item" alt="" /></div>
        </div>
        <div class="title">视频</div>
        <div class="content">
            <div v-for="(item, index) in data.videoUrl" :key="index">
                <video v-if="item" :src="item" controls></video>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import { getCreationDetails } from '@/api';
export default {
    // import 引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
				{
					title: '发现',
				},
				{
					title: '文明创作',
					to: {path: '/civilizedCreation'}
				},
				{
					title: '详情'
				}
            ],
            data: {},
            options: ['全部', '书法', '舞蹈', '音乐', '美术', '摄影', '文艺鉴赏', '其他'],
        };
    },
    props: {},
    // 方法集合
    methods: {
        getCivilizedDetail() {
            getCreationDetails(this.$route.query.id).then((res) => {
                res.data.picUrl = res.data.picUrl.split(',');
                res.data.videoUrl = res.data.videoUrl.split(',');
                this.data = res.data;
            });
        },
    },
    // 计算属性 类似于 data 概念
    computed: {},
    // 监控 data 中的数据变化
    watch: {},
    //过滤器
    filters: {},
    // 生命周期 - 创建之前
    beforeCreate() {},
    // 生命周期 - 创建完成（可以访问当前this 实例）
    created() {
        this.getCivilizedDetail(this.$route.query.id);
    },
    // 生命周期 - 挂载之前
    beforeMount() {},
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    mounted() {},
    // 生命周期 - 更新之前
    beforeUpdate() {},
    // 生命周期 - 更新之后
    updated() {},
    // 生命周期 - 销毁之前
    beforeDestroy() {},
    // 生命周期 - 销毁完成
    destroyed() {},
    // 如果页面有 keep-alive 缓存功能,这个函数会触发
    //进入的时候触发
    activated() {},
    //离开的时候触发
    deactivated() {},
};
</script>

<style lang="less" scoped>
.detail {
    display: flex;
    img {
        width: 580px;
        height: 385px;
        border-radius: 10px;
        margin-right: 24px;
    }
    .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        div:nth-child(1) {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
        }
        span:nth-child(2) {
            padding: 2px 5px;
            border: 1px solid #f33f3e;
            border-radius: 2px;
            font-size: 16px;
            margin: 32px 0 14px 0;
            color: #f33f3e;
            display: inline-block;
        }
        div:last-child {
            font-size: 16px;
            color: #666666;
        }
    }
}
.title {
    font-weight: 700;
    font-size: 24px;
    margin: 24px 0;
}
.content {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 16px;
    div {
        margin-bottom: 16px;
        img,
        video {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
        }
    }
}
</style>
